import React from 'react';
import { Link } from 'react-router-dom';

const ConvincedWork = () => {
    return (
        <div>
            <div className="container text-dark p-4">
                    <h1 className="text-center"><b>Convinced to work on a</b></h1>
                    <h1 className="text-center"><b>new project?</b></h1>
                    <Link to="/contact">
                        <button type="button" className="btn btn-primary text-center mx-auto d-flex mt-3">CONTACT US</button>
                    </Link>
                    
            </div>
        </div>
    );
};

export default ConvincedWork;