import React from 'react';

const Counter = () => {
    return (
        <div>
            <section>
                <div className="container-fluid bg-primary text-light p-4 mb-2">
                    <div className="container p-3">
                        <div className="row">
                            <div className="col-sm-3">
                                <h4 className="text-center">100+</h4>
                                <p className="text-center">Agent and Agency</p>
                            </div>
                            <div className="col-sm-3">
                                <h4 className="text-center">150+</h4>
                                <p className="text-center">Happy Customer Every Year</p>
                            </div>
                            <div className="col-sm-3">
                                <h4 className="text-center">20+</h4>
                                <p className="text-center">Won Awards</p>
                            </div>
                            <div className="col-sm-3">
                                <h4 className="text-center">100+</h4>
                                <p className="text-center">New Listing Every Year</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Counter;