import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocation, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

const TopNav = () => {

    return (
        <div>
            <div className="bg-primary text-light top-nav p-1 text-center">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-4">
                            <div className='text-center'><FontAwesomeIcon icon={faLocation}/> Rangpur, Bangladesh </div>
                        </div>
                        <div className="col-sm-4">
                            <div className='text-center'><FontAwesomeIcon icon={faEnvelope} /> zahidhr99@gmail.com</div>
                        </div>
                        <div className="col-sm-4">
                            <div className='text-center'><FontAwesomeIcon icon={faPhone} /> +8801986686158</div>
                        </div>
                            

{/*                        
                               <div className="col-sm-1 hide">  
                                    <Link className="nav-link text-light" to="/login"><b> Login</b></Link>
                                </div>
                                <div className="col-sm-1 hide">      
                                    <Link className="nav-link text-light" to="/reg"><b>Registration</b></Link>
                                </div> */}
                    
                    </div> 

                </div>
            </div>
        </div>
    );
};

export default TopNav;