import React from 'react';
import { Link } from 'react-router-dom';
import ConvincedWork from '../components/ConvincedWork';
import Counter from '../components/Counter';
import Review from '../components/Review';
import Services from '../components/Services';
import WhatWeDo from '../components/WhatWeDo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons';


const Service = () => {
    return (
        <div>
                <div className="bg-image">
                    <img
                        src="/service-bg.png"
                        className="img-fluid header-image back-img"
                        alt="Sample"
                    />
                    <div className="mask" style={{ backgroundColor: 'rgba(0, 0, 200, 0.6)' }}>
                            <h1 className="text-center text-light p-4 mt-4">MY SERVICES</h1>
                                <p className="text-center text-light percent-70 d-flex mx-auto">
                                I am providing of creative website and software development offering professional, affordable, and amazing development services. I can develop that stays true to your business goals, spreads your business message effectively, and represents you as a brand.
                            </p>
                            <div className="row">
                                <div className="col-sm-3"></div>
                                <div className="col-sm-6">
                                    <div className="text-center mt-4">
                                            <Link to="/contact">
                                                <button type="button" className="btn btn-contact me-2">CONTACT</button>
                                            </Link>

                                            <a href="https://join.skype.com/invite/uaf7aAvVkbnx" target="_blank"><button type="button" className="btn btn-success skype-btm"><FontAwesomeIcon icon={faLocationArrow}/> Connect me on Skype</button></a>
                                    </div> 
                                </div>
                                <div className="col-sm-3"></div>
                            </div>
                    </div>
                </div>

                <WhatWeDo/>

                <Services/>

                <Counter/>

                <ConvincedWork/>

                <Review/>
        </div>
    );
};

export default Service;