import React from 'react';
import { Link } from 'react-router-dom';

const TeamMember = () => {
    return (
        <div>
            <div>
                <div className='container p-3 mt-4'>
                    <div className='row'>
                        <div className='col-sm-6'>
                            <Link to="#">My Team Member</Link>
                            <h2><b>We Have Professionals <br/> Team.</b></h2>
                        </div>
                        <div className='col-sm-6'>
                            <p>We are the leading web developer Team. We have different sectors that is wordpress, website development, software development, android/ios apps development, desktop application development.</p>
                        </div>
                    </div>

                    <div className='row mt-4 mb-4'>
                        <div className='col-sm-4 mb-5'>
                            <div className="card team-card">
                                <img src="/me.png" className="team-img" alt="Sunset Over the Sea"/>

                                <div className="shadow p-2 bg-light text-dark team-size">
                                    <h5 className='text-center'><b>Zahid HR</b></h5>
                                    <p className='text-center text-dark'>Founder and CEO</p>
                                </div>
                            </div>
                        </div>
                   
                        <div className='col-sm-4 mb-5'>
                            <div className="card team-card">
                                <img src="/me.png" className="team-img" alt="Sunset Over the Sea"/>

                                <div className="shadow p-2 bg-light text-dark team-size">
                                    <h5 className='text-center'><b>Zahid HR</b></h5>
                                    <p className='text-center text-dark'>MERN Stack Developer</p>
                                </div>
                            </div>
                        </div>

                        <div className='col-sm-4 mb-5'>
                            <div className="card team-card">
                                <img src="/me.png" className="team-img" alt="Sunset Over the Sea"/>

                                <div className="shadow p-2 bg-light text-dark team-size">
                                    <h5 className='text-center'><b>Zahid HR</b></h5>
                                    <p className='text-center text-dark'>PHP Laravel Developer</p>
                                </div>
                            </div>
                        </div>

                        <div className='col-sm-4 mb-5'>
                            <div className="card team-card team-img">
                                <img src="/me.png" className="team-img" alt="Sunset Over the Sea"/>

                                <div className="shadow p-2 bg-light text-dark team-size">
                                    <h5 className='text-center'><b>Zahid HR</b></h5>
                                    <p className='text-center text-dark'>Android/iOS Developer</p>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>
            </div>
        </div>
    );
};

export default TeamMember;