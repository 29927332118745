import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const Review = () => {
    return (
        <div>
            <div className="container-fluid mt-3">
                <div className="row">
                    <div className="col-sm-3">

                    </div>
                    <div className="col-sm-6">
                        <p className='text-center text-primary'>Testimonial</p>
                        <h3 className='text-center'><b>Our Happy Clients Says</b></h3>
                        <p className='text-center mt-4'>I can say Goodbye to the monthly bill generate using ms excel pains I had to ng has finally become fun, fast and bear with, and hello to a way to do finance that makes sense.</p>
                        <div className="d-flex justify-content-center mt-4 mb-5">
                            <div className="me-3">
                                <img src="/clint.jpeg" width={60} height={60} className="rounded-circle" alt=""/>
                            </div>
                            <div className="">
                                <FontAwesomeIcon icon={faStar} className="text-warning me-1"/>
                                <FontAwesomeIcon icon={faStar} className="text-warning me-1"/>
                                <FontAwesomeIcon icon={faStar} className="text-warning me-1"/>
                                <FontAwesomeIcon icon={faStar} className="text-warning me-1"/>
                                <FontAwesomeIcon icon={faStar} className="text-warning me-1"/>
                                <FontAwesomeIcon icon={faStar} className="text-warning me-1"/>
                                <div><h5><b>Engr. Ashikur rahman</b></h5></div>
                                <span><p>Chief Executive Officer (CEO) at ARESS BD</p></span>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3">

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Review;