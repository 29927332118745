import React, { useRef } from 'react';
import Review from './Review';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarker, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import emailjs from '@emailjs/browser';

const ContactSlider = () => {
    const form = useRef();
    const sendEmail = (e) => {
      e.preventDefault();
      
      emailjs.sendForm('service_3ljq28l', 'template_0xqwgza', form.current, '8ZWiqYHbwcTO8GdSq')
        .then((result) => {
            console.log(result.text);
            alert('Message send success');
        }, (error) => {
            console.log(error.text);
        });
    };

    return (
        <div>
                    <div className='contact-slider-bg'>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="mt-5">
                                        <h1 className="text-center text-light">CONTACT US</h1>
                                        <p className="text-light text-center mt-4 mb-4 mx-auto percent-70">I am professional full stack web developer and always here to help. My representatives are available 24/7 to assist you with any issues.</p>
                                    </div>
                                    
                                </div>
                            </div>            
                    </div>

                    <div className="contact-map">
                        <div className="row">
                            <div className="col-sm-2"></div>
                            <div className="col-sm-8">
                                <div className="card shadow">
                                        <div className="row">
                                            <div className='col-sm-6'>
                                                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d14375.228508967162!2d89.27522585!3d25.7438911!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1659351475781!5m2!1sen!2sbd"  title="mymap" className='p-1 my-map' width="100%" height="400" style={{ border:'0' }} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                                            </div>

                                            <div className='col-sm-6'>
                                                <div className='p-3'>
                                                    <div className="d-flex">
                                                        <div className="mt-5"><FontAwesomeIcon icon={faMapMarker} className="me-2 text-primary"/></div>
                                                        <div className="mt-5">
                                                            <h5>Address</h5>
                                                            <p>Rangpur, Dhaka, Bangladesh</p>
                                                        </div> 
                                                    </div>

                                                    <div className="d-flex">
                                                        <div className="mt-2"><FontAwesomeIcon icon={faPhone} className="me-3 text-primary"/></div>
                                                        <div className="mt-2">
                                                            <h5>Phone</h5>
                                                            <p>+8801986686158</p>
                                                        </div>
                                                    </div>

                                                    <div className="d-flex">
                                                        <div className="mt-2"><FontAwesomeIcon icon={faEnvelope} className="me-3 text-primary"/></div>
                                                        <div className="mt-2">
                                                            <h5>E-mail</h5>
                                                            <p>zahidhr99@gmail.com</p>
                                                        </div>  
                                                    </div>

                                                </div> 
                                            </div>
                                        </div>
                                </div>
                            </div>
                            <div className="col-sm-2"></div>
                        </div>
                    </div>

                            <div className="container-fluid mt-4">
                                <div className="container p-3">
                                    <h2 className="text-center"><b>CONTACT US</b></h2>
                                    <p className="text-center mx-auto percent-70">Contact page is a common web page on a website for visitors to contact the organization or individual providing the website. Should you have any questions don&apos;t hesitate to me!</p>
                                    <form ref={form} onSubmit={sendEmail}>
                                        <div className="row">
                                            <div className='col-sm-2'></div>
                                            <div className="col-sm-8 shadow p-3">
                                                <h5 className="p-2"><b>GET IN TOUCH</b></h5>
                                                <div className="row">
                                                    <div className="col-sm-6 mb-2">
                                                        <label htmlhtmlFor="name">Name *</label>
                                                        <div className="input-group flex-nowrap">
                                                            <input type="text" className="form-control" name="user_name" placeholder="" required/>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-6 mb-2">
                                                        <label htmlhtmlFor="name">Phone *</label>
                                                        <div className="input-group flex-nowrap">
                                                            <input type="number" className="form-control" name="user_phone" placeholder="" />
                                                        </div>
                                                    </div>
                                                  
                                                    <div className="col-sm-6 mb-2">
                                                        <label htmlhtmlFor="name">Email *</label>
                                                        <div className="input-group flex-nowrap">
                                                            <input type="email" className="form-control" name="user_email" placeholder="" required/>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-6 mb-2">
                                                        <label htmlhtmlFor="name">Website URL</label>
                                                        <div className="input-group flex-nowrap">
                                                            <input type="text" className="form-control" name="user_website" placeholder="" />
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="col-sm-12">
                                                        <label htmlhtmlFor="name">Message *</label>
                                                        <div className="input-group flex-nowrap">
                                                            <textarea className="form-control" name="message" style={{ height: '150px' }} required></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="submit" className="btn btn-primary mt-3 d-flex mx-auto">Send Message</button>
                                            </div>
                                            <div className='col-sm-2'></div>                                   
                                        </div>
                                    </form>
                                </div>
                            </div>

                        <div className="container-fluid bg-primary text-light p-4 mb-2">
                            <div className="container p-3">
                                <p className='text-center'>Find Us</p>
                                <h4 className='text-center'>My Office</h4>
                                    <div className='row'>
                                        <div className="col-sm-4">
                                            <div className="mt-2 mb-3">
                                                <img src="/location.png" className="card-img border-radius" width={350} height={220} alt="Sunset Over the Sea"/>
                                                <h5 className='text-center mt-2'>Rangpur</h5>
                                                <p className='text-center'>Rangpur, Bangladesh</p>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="mt-2 mb-3">
                                                <img src="/location.png" className="card-img border-radius" width={350} height={220} alt="Sunset Over the Sea"/>
                                                <h5 className='text-center mt-2'>Dhaka</h5>
                                                <p className='text-center'>Dhaka Bangladesh</p>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="mt-2 mb-3">
                                                <img src="/location.png" className="card-img border-radius" width={350} height={220} alt="Sunset Over the Sea"/>
                                                <h5 className='text-center mt-2'>Khulna</h5>
                                                <p className='text-center'>Khulna, Bangladesh</p>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>


                   <Review/>
               
            
        </div>
    );
};

export default ContactSlider;