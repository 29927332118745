import React from 'react';
import { Link } from 'react-router-dom';

const Forget = () => {
    return (
        <div>
            <div className="container-fluid">
            
                    <div className="row">
                        <div className="col-sm-2"></div>
                        <div className="col-sm-4">
                            <div className="mt-5">
                                <h2><b>Hi buddy,</b></h2>
                                <h2><b>Welcome Back!</b></h2>
                            </div>
    
                            <div className="card mt-5 mb-5">
                                <div className="card-body">
                                    <div className="p-2">
                                        <h6><b>Still don&apos;t have an account? <Link to="/reg">Signup</Link> </b></h6>
                                       
                                    </div>
    
                                    <div className="mt-3">
                                        <input type="text" className="form-control login-form" placeholder="E-mail address" required/>   
                                    </div>
    
    
    
                                    
    
                                    <div className="d-grid gap-2">
                                        <button className="btn btn-primary btn-lg btn-rounded mt-4">Recover Password</button>
                                    </div>
    
                                    <div className="text-center mt-3">
                                        Already have an account?  
                                            <Link to="/login"> Login </Link>
                                        </div>
    
                                   
                                </div>  
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="forget-image-margin-top">
                                <img src="/login.png" className="login-img" alt="" /> 
                            </div>
                        </div>
                    </div>
                </div>
                
        </div>
    );
};

export default Forget;