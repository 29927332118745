import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

const Contact = () => {
    const form = useRef();
    const sendEmail = (e) => {
      e.preventDefault();
      
      emailjs.sendForm('service_3ljq28l', 'template_0xqwgza', form.current, '8ZWiqYHbwcTO8GdSq')
        .then((result) => {
            console.log(result.text);
            alert('Message send success');
        }, (error) => {
            console.log(error.text);
        });
    };
  
    return (
        <div>
            <section>
                <div className="container-fluid service-dark-bg">
                    <div className="container">
                        <h2 className="text-center p-3"><b>CONTACT US</b></h2>
                        <p className="text-center mx-auto percent-70">Contact page is a common web page on a website for visitors to contact the organization or individual providing the website. Should you have any questions don&apos;t hesitate to me!</p>
                    
                        <div className="card shadow service-dark-bg">
                            <h5 className="p-3"><b>GET IN TOUCH</b></h5>
                            <div className="row p-2">
                                <div className="col-sm-8">
                                    <form ref={form} onSubmit={sendEmail}>
                                        <div className="row">
                                            <div className="col-sm-6 mb-2">
                                            <label htmlhtmlFor="name">Name *</label>
                                                <div className="input-group flex-nowrap">
                                                    <input type="text" className="form-control" name="user_name" placeholder="" required/>
                                                </div>
                                            </div>

                                            <div className="col-sm-6 mb-2">
                                            <label htmlhtmlFor="name">Phone </label>
                                                <div className="input-group flex-nowrap">
                                                    <input type="number" className="form-control" name="user_phone" placeholder=""/>
                                                </div>
                                            </div>
                                           
                                            <div className="col-sm-6 mb-2">
                                            <label htmlhtmlFor="name">Email *</label>
                                                <div className="input-group flex-nowrap">
                                                    <input type="email" className="form-control"  name="user_email" placeholder="" required/>
                                                </div>
                                            </div>

                                            <div className="col-sm-6 mb-2">
                                            <label htmlhtmlFor="name">Website URL</label>
                                                <div className="input-group flex-nowrap">
                                                    <input type="text" className="form-control"  name="user_website" placeholder="" />
                                                </div>
                                            </div>
                                          
                                            <div className="col-sm-12">
                                            <label htmlhtmlFor="name">Message *</label>
                                                <div className="input-group flex-nowrap">
                                                    <textarea className="form-control" name="message" style={{ height: '150px' }} required></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <button type="submit" className="btn btn-primary mt-3 mb-3 d-flex mx-auto">Send Message</button>
                                    </form>
                                </div>
                                <div className="col-sm-4">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d14375.228508967162!2d89.27522585!3d25.7438911!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1659351475781!5m2!1sen!2sbd" title="mymap1" className='custom-map' style={{ border:'0' }} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                            </div>
                        </div>
                        <br/>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Contact;