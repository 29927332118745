import React from 'react';

const Dashboard = () => {

    return (
        <div>
            <div className='container mt-4 mb-4'>
                <div className="row">
                    <div className="col-sm-4">
                        <div className='card mb-2'>

                            <div className='p-3'>
                                <img src="/team-1.png" className="rounded-circle profile-img mx-auto d-flex" alt="Profile"/>
                            </div>

                            <div className="list-group list-group-light" id="list-tab" role="tablist">
                                <a className="list-group-item list-group-item-action active px-3 border-0 mb-2" id="list-home-list"
                                    data-mdb-toggle="list" href="#list-home" role="tab" aria-controls="list-home">Dashboard</a>
                                <a className="list-group-item list-group-item-action px-3 border-0 mb-2" id="list-profile-list"
                                    data-mdb-toggle="list" href="#list-profile" role="tab" aria-controls="list-profile">Profile</a>
                                <a className="list-group-item list-group-item-action px-3 border-0 mb-2" id="list-messages-list"
                                    data-mdb-toggle="list" href="#list-messages" role="tab" aria-controls="list-messages">Messages</a>
                                <a className="list-group-item list-group-item-action px-3 border-0 mb-2" id="list-settings-list"
                                    data-mdb-toggle="list" href="#list-settings" role="tab" aria-controls="list-settings">Settings</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-8">
                        <div className='card'>
                            <div className="tab-content" id="nav-tabContent">
                                <div className="tab-pane fade show active" id="list-home" role="tabpanel"
                                    aria-labelledby="list-home-list">
                                    <div className='card-header bg-secondary text-light'>Dashboard</div>
                                        
                                    <div className="table-responsive">
                                        <table className="table">
                                            <tbody>
                                                <tr>
                                                    <th>Name</th>
                                                    <td>:</td>
                                                    <td>Zahid HR</td>
                                                </tr>
                                                <tr>
                                                    <th>Email</th>
                                                    <td>:</td>
                                                    <td>zahidhr99@gmail.com</td>
                                                </tr>
                                                <tr>
                                                    <th>Phone</th>
                                                    <td>:</td>
                                                    <td>01986686158</td>
                                                </tr>
                                                <tr>
                                                    <th>Date of Birth</th>
                                                    <td>:</td>
                                                    <td>28-08-2001</td>
                                                </tr>
                                                <tr>
                                                    <th>Gender</th>
                                                    <td>:</td>
                                                    <td>Male</td>
                                                </tr>
                                                <tr>
                                                    <th>Address</th>
                                                    <td>:</td>
                                                    <td>Mithapukur, Rangpur</td>
                                                </tr>
                                                <tr>
                                                    <th>Country</th>
                                                    <td>:</td>
                                                    <td>Bangladesh</td>
                                                </tr>
                                                <tr>
                                                    <th>Education Qualification</th>
                                                    <td>:</td>
                                                    <td>BSc Hons in CSE</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                  
                                </div>
                                <div className="tab-pane fade" id="list-profile" role="tabpanel" aria-labelledby="list-profile-list">
                                    <div className='card-header bg-secondary text-light'>Profile</div>
                                        <div className='row'>
                                            <div className='col-sm-7'>
                                                <div className="p-3">
                                                    <div className="">
                                                        <label className="form-label" htmlFor="form12"><b>Name</b></label>
                                                        <input type="text" className="form-control p-1" placeholder="" />   
                                                    </div>
                                                    <div className="">
                                                        <label className="form-label" htmlFor="form12"><b>Date of Birth</b></label>
                                                        <input type="text" className="form-control p-1" placeholder="" />   
                                                    </div>
                                                    <div className="">
                                                        <label className="form-label" htmlFor="form12"><b>Phone</b></label>
                                                        <input type="text" className="form-control p-1" placeholder="" />   
                                                    </div>
                                                    <div className="">
                                                        <label className="form-label" htmlFor="form12"><b>Gender</b></label>
                                                        <input type="text" className="form-control p-1" placeholder="" />   
                                                    </div>
                                                    <div className="">
                                                        <label className="form-label" htmlFor="form12"><b>Address</b></label>
                                                        <input type="text" className="form-control p-1" placeholder="" />   
                                                    </div>
                                                    <div className="">
                                                        <label className="form-label" htmlFor="form12"><b>Country</b></label>
                                                        <input type="text" className="form-control p-1" placeholder="" />   
                                                    </div>
                                                    <div className="">
                                                        <label className="form-label" htmlFor="form12"><b>Educational Qualification</b></label>
                                                        <input type="text" className="form-control p-1" placeholder="" />   
                                                    </div>
                                                    <div className="d-flex">
                                                        <button className="btn btn-sm btn-primary mt-3 mx-auto">Update Now</button>
                                                    </div>
                                                </div>  
                                            </div>
                                            <div className='col-sm-5'>
                                            <div className="p-3">
                                                    <div className="">
                                                        <label className="form-label" htmlFor="form12"><b>Profile Picture</b></label>
                                                        <img src="/team-1.png" width={300} height={300} className="card-img-top" alt="Profile"/>
                                                        <label className="form-label" htmlFor="form12"><b>Upload Photo</b></label>
                                                        <input type="file" className="form-control p-1" placeholder="" />   
                                                    </div>
                                                    <div className="d-flex">
                                                        <button className="btn btn-sm btn-primary mt-3 mx-auto">Update Profile Picture</button>
                                                    </div>
                                                </div>  
                                            </div>
                                        </div>
                                </div>

                                <div className="tab-pane fade" id="list-messages" role="tabpanel" aria-labelledby="list-messages-list">
                                    <div className='card-header bg-secondary text-light'>Message</div>
                                        <div className='p-3'>
                                            <ul className="list-group list-group-light">
                                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                                    <div className="d-flex align-items-center">
                                                    <img src="/team-1.png" width={45} height={45} alt="" className="rounded-circle" />
                                                    <div className="ms-3">
                                                        <p className="fw-bold mb-1">John Doe</p>
                                                        <p className="text-muted mb-0">john.doe@gmail.com</p>
                                                    </div>
                                                    </div>
                                                    <span className="badge rounded-pill badge-success">Active</span>
                                                </li>
                                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                                    <div className="d-flex align-items-center">
                                                    <img src="/team-1.png" width={45} height={45} alt="" className="rounded-circle" />
                                                    <div className="ms-3">
                                                        <p className="fw-bold mb-1">Alex Ray</p>
                                                        <p className="text-muted mb-0">alex.ray@gmail.com</p>
                                                    </div>
                                                    </div>
                                                    <span className="badge rounded-pill badge-primary">Onboarding</span>
                                                </li>
                                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                                    <div className="d-flex align-items-center">
                                                    <img src="/team-1.png" width={45} height={45} alt="" className="rounded-circle" />
                                                    <div className="ms-3">
                                                        <p className="fw-bold mb-1">Kate Hunington</p>
                                                        <p className="text-muted mb-0">kate.hunington@gmail.com</p>
                                                    </div>
                                                    </div>
                                                    <span className="badge rounded-pill badge-warning">Awaiting</span>
                                                </li>
                                            </ul>

                                            <hr/>
                                            
                                            <div className="">
                                                <label className="form-label" htmlFor="form12"><b>Send Message to Admin</b></label>
                                            </div>

                                            <div className="d-flex">
                                                <div className='flex-fill me-2'>
                                                    <input type="text" className="form-control" placeholder="" /> 
                                                </div>
                                                <div className='flex-fill'>
                                                    <button className="btn btn-primary">Send</button>
                                                </div> 
                                            </div>

                                        </div>
                                </div>

                                <div className="tab-pane fade" id="list-settings" role="tabpanel" aria-labelledby="list-settings-list">
                                    <div className='card-header bg-secondary text-light'>Settings</div>
                                        <div className="p-3">
                                            <h5>Change Password</h5>
                                            <div className="">
                                                <label className="form-label" htmlFor="form12"><b>New Password</b></label>
                                                <input type="password" className="form-control" placeholder="Enter New email" />   
                                            </div>
                                            <div className="mt-2">
                                                <label className="form-label" htmlFor="form12"><b>Confirm Password</b></label>
                                                <input type="password" className="form-control" placeholder="Enter Confirm password" />   
                                            </div>
                                            <div className="">
                                                <button className="btn btn-sm btn-primary mt-3">UPDATE NOW</button>
                                            </div>
                                        </div>  

                                        <hr/>

                                        <div className="ms-3">
                                            <h5>Forget Password</h5>
                                            <div className="">
                                                <button className="btn btn-sm btn-danger mt-1">Password Recover</button>
                                            </div>
                                        </div>  
                                        <hr/>

                                        <div className="ms-3 mb-4">
                                            <h5>Delete Account</h5>
                                            <div className="">
                                                <button className="btn btn-sm btn-danger mt-1">Delete Account</button>
                                            </div>
                                        </div>  
                            
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;