import React from 'react';
import { Link } from 'react-router-dom';

const Services = () => {
    return (
        <div>
            <section>
                <div className="container-fluid mt-3 mb-3 p-3 service-dark-bg">
                    <h2 className="text-center"><b>I am Moving Forward Successfully</b></h2>
                    <h2 className="text-center"><b>Due To Our Service</b></h2>
                    <p className="text-center mx-auto percent-70">I am highly skilled and professionals in Web Development. it is working 24/7 meaning at any time, all year round. Thus, I ensure the best of services wherever and whenever.</p>
                
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-3">
                                <Link to="/service-details">
                                    <div className="card service-card bg-service-1 mb-3 text-light">
                                        <div className="card-body text-center">
                                            <img src="/wp.png" width={100} height={100} className="mx-auto d-flex p-3" alt="Design"/>
                                            <h5 className="card-title text-center"><b>Wordpress Development</b></h5>
                                            <p className="card-text text-center">We have skilled WordPress developers to develop dynamic web sites in fast time</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>

                            <div className="col-sm-3">
                                <Link to="/service-details">
                                    <div className="card service-card bg-service-2 mb-3 mt-3 text-light">
                                        <div className="card-body text-center">
                                            <img src="/ui.png" width={100} height={100} className="mx-auto d-flex p-3" alt="Design"/>
                                            <h5 className="card-title text-center">UI/UX Design</h5>
                                            <p className="card-text text-center">We have experienced designers for attractive UI/UX design for your website</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-sm-3">
                                <Link to="/service-details">
                                    <div className="card service-card bg-service-4 text-light">
                                        <div className="card-body text-center">
                                            <img src="/code.png" width={100} height={100} className="mx-auto d-flex p-3" alt="Design"/>
                                            <h5 className="card-title text-center">Custom Website Development</h5>
                                            <p className="card-text text-center">You can develop your website according to your business requirement. I am providing.</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-sm-3">
                                <Link to="/service-details">
                                    <div className="card service-card bg-service-3 mb-3 mt-3 text-light">
                                        <div className="card-body text-center">
                                            <img src="/soft.png" width={100} height={100} className="mx-auto d-flex p-3" alt="Design"/>
                                            <h5 className="card-title text-center">Software Development</h5>
                                            <p className="card-text text-center">You can develop your software according to your business requirement. I am providing.</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-sm-3">
                                <Link to="/service-details">
                                    <div className="card service-card bg-service-5 text-light">
                                        <div className="card-body text-center">
                                            <img src="/code.png" width={100} height={100} className="mx-auto d-flex p-3" alt="Design"/>
                                            <h5 className="card-title text-center">Template Design</h5>
                                            <p className="card-text text-center">You can design any colorful template for your website or software at low cost from me.</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-sm-3">
                                <Link to="/service-details">
                                    <div className="card service-card bg-service-6 mb-3 mt-3 text-light">
                                        <div className="card-body text-center">
                                            <img src="/code.png" width={100} height={100} className="mx-auto d-flex p-3" alt="Design"/>
                                            <h5 className="card-title text-center">Android/iOS Apps Development</h5>
                                            <p className="card-text text-center">You can develop your android/ios apps according to your business requirement.</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-sm-3">
                                <Link to="/service-details">
                                    <div className="card service-card bg-service-7 text-light">
                                        <div className="card-body text-center">
                                            <img src="/soft.png" width={100} height={100} className="mx-auto d-flex p-3" alt="Design"/>
                                            <h5 className="card-title text-center">Desktop Apps Development</h5>
                                            <p className="card-text text-center">You can develop your desktop apps according to your business requirement from me.</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-sm-3">
                                <Link to="/service-details">
                                    <div className="card service-card bg-service-8 mb-3 mt-3 text-light">
                                        <div className="card-body text-center">
                                            <img src="/code.png" width={100} height={100} className="mx-auto d-flex p-3" alt="Design"/>
                                            <h5 className="card-title text-center">Website Development</h5>
                                            <p className="card-text text-center">You can develop any type of website you like from me.</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Services;