import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDesktop, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const About = () => {
    return (
        <div>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="p-3">
                                <img src="/about-bg.png" className='home-img' alt=""  />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="p-2">
                                <Link to="/about">ABOUT ME</Link>
                                <h2><b>I have been providing web development services for 6+ years</b></h2>
                                <p>I promise to provide you with unlimited revisions, so you are guaranteed to get the work you want for one affordable price.</p>
                                <h4><b><FontAwesomeIcon icon={faDesktop}/> The Great Developers</b></h4>
                                <p>I am professional freelancer for full stack development and ready to develop your perfect website or software.</p>
                                <h4><b><FontAwesomeIcon icon={faLightbulb}/> 24/7 Client Support</b></h4>
                                <p>My skilled support team are ready to respond to and resolve incidents 24/7, 365..</p>
                                <hr/>
                                <div className="d-flex flex-row mb-3">
                                    <div className=""><img src="/me.png" className="founder-style founder-img" alt=""/></div>
                                    <div className="p-2">
                                        <h5><b>Zahid HR</b></h5>
                                        <p>Software Developer</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default About;