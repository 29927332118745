import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

const FAQ = () => {
    return (
        <div className='service-dark-bg'>
            <div className='container'> <br/> <br/>
                    <h2 className="text-center"><b>FREQUENTLY ASKED QUESTION</b></h2>
                    <p className="text-center mx-auto percent-70">I am providing of creative website and software development offering professional, affordable, and amazing development services.</p>
                

                <div className='row mb-4'>
                    <div className='col-sm-6'>
                        <div className="accordion" id="accordionExampleY">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOneY">
                                <button className="accordion-button" type="button" data-mdb-toggle="collapse"
                                    data-mdb-target="#collapseOneY" aria-expanded="true" aria-controls="collapseOneY">
                                    <FontAwesomeIcon icon={faQuestionCircle} className="me-2 opacity-70"/>
                                    Why choose my service?
                                </button>
                                </h2>
                                <div id="collapseOneY" className="accordion-collapse collapse show" aria-labelledby="headingOneY"
                                data-mdb-parent="#accordionExampleY">
                                <div className="accordion-body"> 
                                    <li className="list-group">1. Creative, Modern and attractive design.</li>
                                    <li className="list-group">2. Code simplicity and first.</li>
                                    <li className="list-group">3. First loaded guaranty</li>
                                    <li className="list-group">4. Security Ensure.</li>
                                    <li className="list-group">5. Dynamic website development.</li>
                                    <li className="list-group">6. Any kinds of software development.</li>
                                    <li className="list-group">7. Custom design and development.</li>
                                    <li className="list-group">8. Strong business logic.</li>
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingFourY">
                                <button className="accordion-button collapsed" type="button" data-mdb-toggle="collapse"
                                    data-mdb-target="#collapseFourY" aria-expanded="false" aria-controls="collapseFourY">
                                    <FontAwesomeIcon icon={faQuestionCircle} className="me-2 opacity-70"/>What are your services?
                                </button>
                                </h2>
                                <div id="collapseFourY" className="accordion-collapse collapse" aria-labelledby="headingFourY"
                                data-mdb-parent="#accordionExampleY">
                                <div className="accordion-body">
                                    <li className="list-group">1. Wordpress development.</li>
                                    <li className="list-group">2. PHP, Laravel website and software development.</li>
                                    <li className="list-group">3. MERN full stack development.</li>
                                    <li className="list-group">4. Android/iOS apps development.</li>
                                    <li className="list-group">5. Desktop application development.</li>
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTwoY">
                                <button className="accordion-button collapsed" type="button" data-mdb-toggle="collapse"
                                    data-mdb-target="#collapseTwoY" aria-expanded="false" aria-controls="collapseTwoY">
                                    <FontAwesomeIcon icon={faQuestionCircle} className="me-2 opacity-70"/>Who am I?
                                </button>
                                </h2>
                                <div id="collapseTwoY" className="accordion-collapse collapse" aria-labelledby="headingTwoY"
                                data-mdb-parent="#accordionExampleY">
                                <div className="accordion-body">
                                I AM PROFESSIONAL WEB AND SOFTWARE DEVELOPER. I have global creative website design platform that makes it easy for clients and freelance developers to work together online. Since 2016. As part of the CODE WITH ZAHID, I was dedicated to helping small businesses thrive through expert development.
                                </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingThreeY">
                                <button className="accordion-button collapsed" type="button" data-mdb-toggle="collapse"
                                    data-mdb-target="#collapseThreeY" aria-expanded="false" aria-controls="collapseThreeY">
                                    <FontAwesomeIcon icon={faQuestionCircle} className="me-2 opacity-70"/>Do we provide source code?
                                </button>
                                </h2>
                                <div id="collapseThreeY" className="accordion-collapse collapse" aria-labelledby="headingThreeY"
                                data-mdb-parent="#accordionExampleY">
                                <div className="accordion-body">
                                    Yes of course We provide the source code.
                                </div>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <div className="">
                            <img src="/faq.png" className="p-2 faq-img" alt="" /> 
                        </div>
                    </div>
                </div>
                <br/> <br/>
            </div>
        </div>
    );
};

export default FAQ;