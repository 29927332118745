import React from 'react';
import { Link } from 'react-router-dom';
import Contact from '../components/Contact';
import ConvincedWork from '../components/ConvincedWork';
import Counter from '../components/Counter';
import HowWeAre from '../components/HowWeAre';
import Progress from '../components/Progress';
import Review from '../components/Review';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import FAQ from '../components/FAQ';

const About = () => {
    return (
        <div>
            <div className="bg-image">
                <img
                    src="/about.png"
                    className="img-fluid header-image back-img"
                    alt="Sample"
                />

                <div className="mask" style={{ backgroundColor: 'rgba(0, 0, 200, 0.6)' }}>
                        <h1 className="text-center text-light p-4 mt-4">ABOUT ME</h1>
                            <p className="text-center text-light percent-70 d-flex mx-auto">
                            I am providing of creative website and software development offering professional, affordable, and amazing development services. I can develop that stays true to your business goals, spreads your business message effectively, and represents you as a brand.
                        </p>
                        <div className="row">
                            <div className="col-sm-3"></div>
                            <div className="col-sm-6">
                               
                                    <div className="mt-4 text-center">
                                            <Link to="/contact">
                                                <button type="button" className="btn btn-contact me-2">Hire Me</button>
                                            </Link>

                                            <a href="https://join.skype.com/invite/uaf7aAvVkbnx" target="_blank"><button type="button" className="btn btn-success skype-btm"><FontAwesomeIcon icon={faLocationArrow}/> Connect me on Skype</button></a>
                                    </div> 
                            </div>
                            <div className="col-sm-3"></div>
                        </div>
                </div>
            </div>

        <HowWeAre/>

        <Progress/>

        <FAQ/>

        {/* <Team/> */}

        <Counter/>

        <ConvincedWork/>

        <Review/>

        <Contact/> 

        </div>
    );
};

export default About;