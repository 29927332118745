import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faHome, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div>
            <footer  className="text-center text-lg-start bg-primary text-light">
              
              <section  className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
                    
                  <div  className="me-5 d-none d-lg-block">
                  <span>Get connected with me on social networks:</span>
                  </div>
                      
                  <div>
                    <a className="btn text-white btn-rounded me-2 mb-1 mt-1" style={{ backgroundColor: '#4FC65A' }} href="https://wa.me/+8801986686158" target="_blank" role="button"> What's App</a> 
                    <a className="btn text-white btn-rounded me-2 mb-1 mt-1" style={{ backgroundColor: '#3b5998' }} href="https://www.facebook.com/zahidhr99" target="_blank" role="button">Facebook</a>
                    <a className="btn text-white btn-rounded me-2 mb-1 mt-1" style={{ backgroundColor: '#1C96E8' }} href="https://twitter.com/ZahidHR99" target="_blank" role="button"> Twitter</a>
                    <a className="btn text-white btn-rounded me-2 mb-1 mt-1" style={{ backgroundColor: '#0073B1' }} href="https://www.linkedin.com/in/zahid-hr-619561183/" target="_blank" role="button"> Linkedin</a> 
                    <a className="btn text-white btn-rounded me-2 mb-1 mt-1" style={{ backgroundColor: '#000000' }} href="https://github.com/ZahidHR99" target="_blank" role="button"> GitHub</a> 
                    <a className="btn text-white btn-rounded me-2 mb-1 mt-1" style={{ backgroundColor: '#C436A0' }} href="https://www.instagram.com/zahid_hr_pro/" target="_blank" role="button"> Instagram</a> 
                    <a className="btn text-white btn-rounded me-2 mb-1 mt-1" style={{ backgroundColor: '#0099D6' }} href="https://join.skype.com/invite/uaf7aAvVkbnx" target="_blank" role="button"> Skype</a>  
                    <a className="btn text-white btn-rounded me-2 mb-1 mt-1" style={{ backgroundColor: '#0396DE' }} href="https://t.me/zahidhr" target="_blank" role="button"> Telegram</a> 
                </div>
                      
              </section>
               
              <section  className="">
                  <div  className="container text-center text-md-start mt-5">
                  
                  <div  className="row mt-3">
                        <div className="col-sm-4 mb-4">
                            <h6 className="fw-bold mb-4">
                                About Us
                            </h6>
                            <p>
                            I am professional full stack web developer and providing creative website and software development offering professional, affordable, and amazing development services.
                            </p>
                        </div>
                        <div className="col-sm-2 mb-4">
                            <h6  className="fw-bold mb-4">
                                Quick Links
                            </h6>
                            <p><Link to="/about"  className="text-reset">About</Link></p>
                            <p><Link to="/service"  className="text-reset">Service</Link></p>
                            <p><Link to="/portfolio"  className="text-reset">Portfolio</Link></p>
                            <p><Link to="/contact"  className="text-reset">Contact</Link></p>
                        </div>
                        <div  className="col-sm-3 mb-4">
                            <h6  className="fw-bold mb-4">
                                Services
                            </h6>
                            <p><Link to="#!"  className="text-reset">Wordpress Development</Link></p>
                            <p><Link to="#!"  className="text-reset">PHP/Laravel Application</Link></p>
                            <p><Link to="#!"  className="text-reset">React+Node Application</Link></p>
                            <p><Link to="#!"  className="text-reset">Android/iOS Application</Link></p>
                        </div>
                        <div  className="col-sm-3 mb-4">
                            <h6  className="fw-bold mb-4">Contact Us</h6>
                            <p><FontAwesomeIcon icon={faHome} className="me-2"/> Rangpur, Dhaka, Bangladesh</p>
                            <p><FontAwesomeIcon icon={faEnvelope} className="me-2"/> zahidhr99@gmail.com</p>
                            <p><FontAwesomeIcon icon={faPhone} className="me-2"/> +8801986686158</p>
                            <p><FontAwesomeIcon icon={faPhone} className="me-2"/> +8801568450601</p>
                        </div>    
                    </div>
                  
                  </div>
              </section>
               
               <hr/>
  
              <div  className="text-center p-2">
               <p>Copyright © 2023, CODEWITHZAHID.COM All Rights Reserves.</p>
              </div>
     
              </footer>
  
             
         
        </div>
    );
};

export default Footer;