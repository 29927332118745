import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const WhatWeDo2 = () => {
    return (
        <div>
            <div className="container mt-4 mb-4">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="p-2">
                                <i className="fas fa-bullhorn mb-2" style={{ fontSize: '40px', transform: 'rotate(340deg)' }}></i>
                                <h2><b>What I Do?</b></h2>
                                <p><b>The service we offer is specifically development to meet your needs.</b></p>
                                <p>We are global creative website design platform that makes it easy for clients and freelance developers to work together online. Since 2016. As part of the CODE WITH ZAHID, I was dedicated to helping small businesses thrive through expert development.</p>
                                <div className='row'>
                                    <div className='col-sm-6'>
                                        <p><FontAwesomeIcon icon={faCheckCircle} className="text-primary"/> Professional freelance developers around the ready to create your website.</p>
                                        <p><FontAwesomeIcon icon={faCheckCircle} className="text-primary"/> We provide projects start to receive designs within a few hours.</p>
                                        <p><FontAwesomeIcon icon={faCheckCircle} className="text-primary"/> I am always here to help. Our representatives are available 24/7 to assist you with any issues.</p>
                                    </div>
                                    <div className='col-sm-6'>
                                        <p><FontAwesomeIcon icon={faCheckCircle} className="text-primary"/> Our custom design and development starts at you want for one your affordable price.</p>
                                        <p><FontAwesomeIcon icon={faCheckCircle} className="text-primary"/> We provide quick turnaround for simple tasks, longer timelines for big projects.</p>
                                        <p><FontAwesomeIcon icon={faCheckCircle} className="text-primary"/> You can live chat with our freelancers to ask questions for your project.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="p-3">
                                <img src="/service.png" className='service-img' alt=""  />
                            </div>
                        </div>
                    </div>
                </div>

        </div>
    );
};

export default WhatWeDo2;