import React from 'react';
import { Link } from 'react-router-dom';

const Progress = () => {
    return (
        <div>
            <div className='container-fluid progress-dark-bg'>
                    <div className='container p-4'><br/>
                        <div className='row'>
                            <div className='col-sm-6'>
                                <Link to="#">Have a Nice Day!</Link>
                                <h2 className='mt-2'><b>My Professional Service <br/> Skill Here</b></h2>
                            </div>
                            <div className='col-sm-6'>
                                <p className='mt-3'>With 6 years experience as a professional a full stack developer, I have acquired the skills and knowledge necessary to make your project a success.</p>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-6 mt-4'>
                                <div className='mb-3'>
                                    <div className="d-flex mb-1">
                                        <div className="">HTML5</div>
                                        <div className="ms-auto">90%</div>
                                    </div>
                                    <div className="progress bg-dark">
                                        <div className="progress-bar bg-success" style={{ width: '90%' }} role="progressbar" aria-valuenow="98" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>

                                <div className='mb-3'>
                                    <div className="d-flex mb-1">
                                        <div className="">CSS3</div>
                                        <div className="ms-auto">90%</div>
                                    </div>
                                    <div className="progress bg-dark">
                                        <div className="progress-bar bg-info" style={{ width: '90%' }} role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>

                                <div className='mb-3'>
                                    <div className="d-flex mb-1">
                                        <div className="">Bootstrap5</div>
                                        <div className="ms-auto">90%</div>
                                    </div>
                                    <div className="progress bg-dark">
                                        <div className="progress-bar bg-warning" style={{ width: '90%' }} role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>

                                <div className='mb-3'>
                                    <div className="d-flex mb-1">
                                        <div className="">TailWind CSS</div>
                                        <div className="ms-auto">85%</div>
                                    </div>
                                    <div className="progress bg-dark">
                                        <div className="progress-bar bg-danger" style={{ width: '85%' }} role="progressbar" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>

                                <div className='mb-3'>
                                    <div className="d-flex mb-1">
                                        <div className="">Material UI (MUI)</div>
                                        <div className="ms-auto">85%</div>
                                    </div>
                                    <div className="progress bg-dark">
                                        <div className="progress-bar bg-primary" style={{ width: '85%' }} role="progressbar" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>

                                <div className='mb-3'>
                                    <div className="d-flex mb-1">
                                        <div className="">PHP 8.1</div>
                                        <div className="ms-auto">70%</div>
                                    </div>
                                    <div className="progress bg-dark">
                                        <div className="progress-bar bg-success" style={{ width: '70%' }} role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>

                                <div className='mb-3'>
                                    <div className="d-flex mb-1">
                                        <div className="">Laravel 8.x, 9.x</div>
                                        <div className="ms-auto">80%</div>
                                    </div>
                                    <div className="progress bg-dark">
                                        <div className="progress-bar bg-warning" style={{ width: '80%' }} role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>

                                <div className='mb-3'>
                                    <div className="d-flex mb-1">
                                        <div className="">VUE JS</div>
                                        <div className="ms-auto">70%</div>
                                    </div>
                                    <div className="progress bg-dark">
                                        <div className="progress-bar bg-secondary" style={{ width: '70%' }} role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>

                                <div className='mb-3'>
                                    <div className="d-flex mb-1">
                                        <div className="">JavaScript</div>
                                        <div className="ms-auto">60%</div>
                                    </div>
                                    <div className="progress bg-dark">
                                        <div className="progress-bar bg-info" style={{ width: '60%' }} role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>

                                <div className='mb-3'>
                                    <div className="d-flex mb-1">
                                        <div className="">TypeScript</div>
                                        <div className="ms-auto">50%</div>
                                    </div>
                                    <div className="progress bg-dark">
                                        <div className="progress-bar bg-warning" style={{ width: '50%' }} role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>

                                <div className='mb-3'>
                                    <div className="d-flex mb-1">
                                        <div className="">Wordpress</div>
                                        <div className="ms-auto">80%</div>
                                    </div>
                                    <div className="progress bg-dark">
                                        <div className="progress-bar bg-primary" style={{ width: '80%' }} role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>

                            </div>

                            <div className='col-sm-6 mt-4'>
                                <div className='mb-3'>
                                    <div className="d-flex mb-1">
                                        <div className="">React JS</div>
                                        <div className="ms-auto">95%</div>
                                    </div>
                                    <div className="progress bg-dark">
                                        <div className="progress-bar bg-info" style={{ width: '95%' }} role="progressbar" aria-valuenow="95" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>

                                <div className='mb-3'>
                                    <div className="d-flex mb-1">
                                        <div className="">Redux</div>
                                        <div className="ms-auto">60%</div>
                                    </div>
                                    <div className="progress bg-dark">
                                        <div className="progress-bar bg-success" style={{ width: '60%' }} role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>

                                <div className='mb-3'>
                                    <div className="d-flex mb-1">
                                        <div className="">Next JS</div>
                                        <div className="ms-auto">70%</div>
                                    </div>
                                    <div className="progress bg-dark">
                                        <div className="progress-bar bg-danger" style={{ width: '70%' }} role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <div className="d-flex mb-1">
                                        <div className="">Node JS</div>
                                        <div className="ms-auto">80%</div>
                                    </div>
                                    <div className="progress bg-dark">
                                        <div className="progress-bar bg-secondary" style={{ width: '80%' }} role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>

                                <div className='mb-3'>
                                    <div className="d-flex mb-1">
                                        <div className="">Express JS</div>
                                        <div className="ms-auto">80%</div>
                                    </div>
                                    <div className="progress bg-dark">
                                        <div className="progress-bar bg-success" style={{ width: '80%' }} role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>

                                <div className='mb-3'>
                                    <div className="d-flex mb-1">
                                        <div className="">Mongo DB</div>
                                        <div className="ms-auto">85%</div>
                                    </div>
                                    <div className="progress bg-dark">
                                        <div className="progress-bar bg-primary" style={{ width: '85%' }} role="progressbar" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>

                                <div className='mb-3'>
                                    <div className="d-flex mb-1">
                                        <div className="">MySQL</div>
                                        <div className="ms-auto">95%</div>
                                    </div>
                                    <div className="progress bg-dark">
                                        <div className="progress-bar bg-warning" style={{ width: '95%' }} role="progressbar" aria-valuenow="95" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>

                                <div className='mb-3'>
                                    <div className="d-flex mb-1">
                                        <div className="">Firebase</div>
                                        <div className="ms-auto">85%</div>
                                    </div>
                                    <div className="progress bg-dark">
                                        <div className="progress-bar bg-danger" style={{ width: '85%' }} role="progressbar" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>   

                                <div className='mb-3'>
                                    <div className="d-flex mb-1">
                                        <div className="">React Native</div>
                                        <div className="ms-auto">50%</div>
                                    </div>
                                    <div className="progress bg-dark">
                                        <div className="progress-bar bg-secondary" style={{ width: '50%' }} role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>

                               <div className='mb-3'>
                                    <div className="d-flex mb-1">
                                        <div className="">C, C++, Java</div>
                                        <div className="ms-auto">70%</div>
                                    </div>
                                    <div className="progress bg-dark">
                                        <div className="progress-bar bg-primary" style={{ width: '70%' }} role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>

                                <div className='mb-3'>
                                    <div className="d-flex mb-1">
                                        <div className="">Python</div>
                                        <div className="ms-auto">85%</div>
                                    </div>
                                    <div className="progress bg-dark">
                                        <div className="progress-bar bg-success" style={{ width: '85%' }} role="progressbar" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        {/* <button className="mx-auto d-flex btn btn-primary mt-3">Skill More <FontAwesomeIcon icon={faAngleDoubleRight} className="mt-1 ms-2"/></button> */}

                    </div>
                </div>
        </div>
    );
};

export default Progress;