import React from 'react';
import { Link } from 'react-router-dom';

const Login = () => {

    return (
        <div>
            
            <div className="container-fluid">
                
                <div className="row">
                    <div className="col-sm-2"></div>
                    <div className="col-sm-4">
                        <div className="mt-5">
                            <h2><b>Hi buddy,</b></h2>
                            <h2><b>Welcome Back!</b></h2>
                        </div>

                        <div className="card mt-5 mb-5">
                            <div className="card-body">
                                <div className="p-2">
                                    <h6><b>Still don&apos;t have an account? <Link to="/registration"><Link>Signup</Link></Link> </b></h6>
                                    <label className="form-label" htmlFor="form12"><b>with your social network</b></label><br/>

                                    <Link className="btn text-white btn-rounded me-2 mt-2" style={{ backgroundColor: '#3b5998' }} to="" role="button">
                                       Facebook
                                    </Link>

                                    <Link className="btn text-white btn-rounded mt-2" style={{ backgroundColor: '#dd4b39' }} to="#!" role="button">
                                        Google
                                    </Link> 
                                </div>

                                <form>
                                    <div className="mt-3">
                                        <input type="text" className="form-control login-form" placeholder="E-mail address" required/>   
                                    </div>
                                    <div className="mt-3">                
                                        <input type="password" className="form-control login-form" placeholder="Password" required/>   
                                    </div>
                                    <div className="d-flex mt-3">
                                        <div className="flex-fill form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" required/>
                                            <label className="form-check-label" htmlFor="flexCheckChecked">Keep me signed in</label>
                                        </div>
                                        <div className="flex-fill">
                                            <Link to="/forget">
                                                Forget Password?
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="d-grid gap-2">
                                        <button type="submit" className="btn btn-primary btn-lg btn-rounded mt-3">Continue</button>
                                    </div>

                                </form>

                                <div className="text-center mt-3">
                                    New user? 
                                        <Link to="/reg">
                                            Signup
                                        </Link>
                                    </div>

                               
                            </div>  
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="login-image-margin-top">
                            <img src="/login.png" className="login-img" alt="" /> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;