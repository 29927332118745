import React from 'react';
import PortfolioDesign from '../components/PortfolioDesign';
import Review from '../components/Review';

const PortfolioDetails = () => {
    return (
        <div>
            <div className="bg-image">
                    <img
                        src="/p-d.png"
                        className="img-fluid"
                        alt="Sample"
                    />
                    <div className="mask" style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
                        <div className="d-flex justify-content-center align-items-center h-100">
                            <h2 className="text-white mb-0">PORTFOLIO</h2>
                        </div>
                    </div>
                </div>

                <PortfolioDesign/>

                <Review/>
        </div>
    );
};

export default PortfolioDetails;