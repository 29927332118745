import React from 'react';
import About from '../components/About';
import Contact from '../components/Contact';
import ConvincedWork from '../components/ConvincedWork';
import Counter from '../components/Counter';
import FAQ from '../components/FAQ';
import Portfolio from '../components/Portfolios';
import Progress from '../components/Progress';
import Review from '../components/Review';
import Service from '../components/Services';
import Slider from '../components/Slider';


const Home = () => {
    return (
        <div>
            <Slider/>
            <About/>
            <Progress/>
            <Service/>
            <Portfolio/>
            <FAQ/>
            <Counter/>
            <ConvincedWork/>
            <Review/>
            <Contact/>
        </div>
    );
};

export default Home;