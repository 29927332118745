import React from 'react';
import { Link } from 'react-router-dom';

const PortfolioDesign = () => {
    return (
        <div>
            <div className='container mb-4'>
                <h3 className="text-center mt-3"><b>My Portfolio</b></h3>
                <p className="text-center percent-70 mx-auto">I have expert team of website development they are able to provide 100% efficient service online and is available to build a trusting working relationship with you. Please see our service portfolio.</p>
                    <div className="row">
                    
                        <div className="col-sm-4"> 
                            <Link to="#">
                                <div className="card mt-2 mb-3">
                                    <img src="/projects/01.jpg" className="portfolio-img" alt=""/>
                                    <div className="p-2">
                                        <h4 className="">Exam Software</h4>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <div className="ms-2 me-2">
                                            <img src="/zahidhr.jpg" width={30} height={30} className="rounded-circle" alt=""/>
                                        </div>
                                        <div className="">
                                            <div>Developed by Zahid HR</div> 
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-sm-4"> 
                            <Link to="#">
                                <div className="card mt-2 mb-3">
                                    <img src="/projects/02.jpg" className="portfolio-img" alt=""/>
                                    <div className="p-2">
                                        <h4 className="">License Software</h4>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <div className="ms-2 me-2">
                                            <img src="/zahidhr.jpg" width={30} height={30} className="rounded-circle" alt=""/>
                                        </div>
                                        <div className="">
                                            <div>Developed by Zahid HR</div> 
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                       
                        <div className="col-sm-4"> 
                            <Link to="#">
                                <div className="card mt-2 mb-3">
                                    <img src="/projects/03.jpg" className="portfolio-img" alt=""/>
                                    <div className="p-2">
                                        <h4 className="">POS Software</h4>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <div className="ms-2 me-2">
                                            <img src="/zahidhr.jpg" width={30} height={30} className="rounded-circle" alt=""/>
                                        </div>
                                        <div className="">
                                            <div>Developed by Zahid HR</div> 
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-sm-4"> 
                            <Link to="#">
                                <div className="card mt-2 mb-3">
                                    <img src="/projects/04.jpg" className="portfolio-img" alt=""/>
                                    <div className="p-2">
                                        <h4 className="">Company Website</h4>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <div className="ms-2 me-2">
                                            <img src="/zahidhr.jpg" width={30} height={30} className="rounded-circle" alt=""/>
                                        </div>
                                        <div className="">
                                            <div>Developed by Zahid HR</div> 
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-sm-4"> 
                            <Link to="#">
                                <div className="card mt-2 mb-3">
                                    <img src="/projects/05.jpg" className="portfolio-img" alt=""/>
                                    <div className="p-2">
                                        <h4 className="">Doctor's Website</h4>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <div className="ms-2 me-2">
                                            <img src="/zahidhr.jpg" width={30} height={30} className="rounded-circle" alt=""/>
                                        </div>
                                        <div className="">
                                            <div>Developed by Zahid HR</div> 
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-sm-4"> 
                            <Link to="#">
                                <div className="card mt-2 mb-3">
                                    <img src="/projects/06.jpg" className="portfolio-img" alt=""/>
                                    <div className="p-2">
                                        <h4 className="">E-Commerce Website</h4>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <div className="ms-2 me-2">
                                            <img src="/zahidhr.jpg" width={30} height={30} className="rounded-circle" alt=""/>
                                        </div>
                                        <div className="">
                                            <div>Developed by Zahid HR</div> 
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-sm-4"> 
                            <Link to="#">
                                <div className="card mt-2 mb-3">
                                    <img src="/projects/07.jpg" className="portfolio-img" alt=""/>
                                    <div className="p-2">
                                        <h4 className="">Courier Service Website</h4>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <div className="ms-2 me-2">
                                            <img src="/zahidhr.jpg" width={30} height={30} className="rounded-circle" alt=""/>
                                        </div>
                                        <div className="">
                                            <div>Developed by Zahid HR</div> 
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-sm-4"> 
                            <Link to="#">
                                <div className="card mt-2 mb-3">
                                    <img src="/projects/08.jpg" className="portfolio-img" alt=""/>
                                    <div className="p-2">
                                        <h4 className="">Courier Service Website</h4>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <div className="ms-2 me-2">
                                            <img src="/zahidhr.jpg" width={30} height={30} className="rounded-circle" alt=""/>
                                        </div>
                                        <div className="">
                                            <div>Developed by Zahid HR</div> 
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                   
                        <div className="col-sm-4"> 
                            <Link to="#">
                                <div className="card mt-2 mb-3">
                                    <img src="/projects/09.jpg" className="portfolio-img" alt=""/>
                                    <div className="p-2">
                                        <h4 className="">E-Commerce Website</h4>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <div className="ms-2 me-2">
                                            <img src="/zahidhr.jpg" width={30} height={30} className="rounded-circle" alt=""/>
                                        </div>
                                        <div className="">
                                            <div>Developed by Zahid HR</div> 
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-sm-4"> 
                            <Link to="#">
                                <div className="card mt-2 mb-3">
                                    <img src="/projects/10.jpg" className="portfolio-img" alt=""/>
                                    <div className="p-2">
                                        <h4 className="">College Website</h4>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <div className="ms-2 me-2">
                                            <img src="/zahidhr.jpg" width={30} height={30} className="rounded-circle" alt=""/>
                                        </div>
                                        <div className="">
                                            <div>Developed by Zahid HR</div> 
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-sm-4"> 
                            <Link to="#">
                                <div className="card mt-2 mb-3">
                                    <img src="/projects/11.jpg" className="portfolio-img" alt=""/>
                                    <div className="p-2">
                                        <h4 className="">Medical Website</h4>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <div className="ms-2 me-2">
                                            <img src="/zahidhr.jpg" width={30} height={30} className="rounded-circle" alt=""/>
                                        </div>
                                        <div className="">
                                            <div>Developed by Zahid HR</div> 
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-sm-4"> 
                            <Link to="#">
                                <div className="card mt-2 mb-3">
                                    <img src="/projects/12.jpg" className="portfolio-img" alt=""/>
                                    <div className="p-2">
                                        <h4 className="">Clint Management Software</h4>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <div className="ms-2 me-2">
                                            <img src="/zahidhr.jpg" width={30} height={30} className="rounded-circle" alt=""/>
                                        </div>
                                        <div className="">
                                            <div>Developed by Zahid HR</div> 
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-sm-4"> 
                            <Link to="#">
                                <div className="card mt-2 mb-3">
                                    <img src="/projects/13.jpg" className="portfolio-img" alt=""/>
                                    <div className="p-2">
                                        <h4 className="">POS Software</h4>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <div className="ms-2 me-2">
                                            <img src="/zahidhr.jpg" width={30} height={30} className="rounded-circle" alt=""/>
                                        </div>
                                        <div className="">
                                            <div>Developed by Zahid HR</div> 
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-sm-4"> 
                            <Link to="#">
                                <div className="card mt-2 mb-3">
                                    <img src="/projects/14.jpg" className="portfolio-img" alt=""/>
                                    <div className="p-2">
                                        <h4 className="">Online Marketing Website</h4>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <div className="ms-2 me-2">
                                            <img src="/zahidhr.jpg" width={30} height={30} className="rounded-circle" alt=""/>
                                        </div>
                                        <div className="">
                                            <div>Developed by Zahid HR</div> 
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-sm-4"> 
                            <Link to="#">
                                <div className="card mt-2 mb-3">
                                    <img src="/projects/15.jpg" className="portfolio-img" alt=""/>
                                    <div className="p-2">
                                        <h4 className="">Company Website</h4>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <div className="ms-2 me-2">
                                            <img src="/zahidhr.jpg" width={30} height={30} className="rounded-circle" alt=""/>
                                        </div>
                                        <div className="">
                                            <div>Developed by Zahid HR</div> 
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-sm-4"> 
                            <Link to="#">
                                <div className="card mt-2 mb-3">
                                    <img src="/projects/16.jpg" className="portfolio-img" alt=""/>
                                    <div className="p-2">
                                        <h4 className="">E-Commerce Website</h4>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <div className="ms-2 me-2">
                                            <img src="/zahidhr.jpg" width={30} height={30} className="rounded-circle" alt=""/>
                                        </div>
                                        <div className="">
                                            <div>Developed by Zahid HR</div> 
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-sm-4"> 
                            <Link to="#">
                                <div className="card mt-2 mb-3">
                                    <img src="/projects/17.jpg" className="portfolio-img" alt=""/>
                                    <div className="p-2">
                                        <h4 className="">School Website</h4>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <div className="ms-2 me-2">
                                            <img src="/zahidhr.jpg" width={30} height={30} className="rounded-circle" alt=""/>
                                        </div>
                                        <div className="">
                                            <div>Developed by Zahid HR</div> 
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                   
                        <div className="col-sm-4"> 
                            <Link to="#">
                                <div className="card mt-2 mb-3">
                                    <img src="/projects/18.jpg" className="portfolio-img" alt=""/>
                                    <div className="p-2">
                                        <h4 className="">Pre-Cadet School Website</h4>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <div className="ms-2 me-2">
                                            <img src="/zahidhr.jpg" width={30} height={30} className="rounded-circle" alt=""/>
                                        </div>
                                        <div className="">
                                            <div>Developed by Zahid HR</div> 
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-sm-4"> 
                            <Link to="#">
                                <div className="card mt-2 mb-3">
                                    <img src="/projects/19.jpg" className="portfolio-img" alt=""/>
                                    <div className="p-2">
                                        <h4 className="">Cafe Billing Software</h4>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <div className="ms-2 me-2">
                                            <img src="/zahidhr.jpg" width={30} height={30} className="rounded-circle" alt=""/>
                                        </div>
                                        <div className="">
                                            <div>Developed by Zahid HR</div> 
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-sm-4"> 
                            <Link to="#">
                                <div className="card mt-2 mb-3">
                                    <img src="/projects/20.jpg" className="portfolio-img" alt=""/>
                                    <div className="p-2">
                                        <h4 className="">E-Commerce Website</h4>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <div className="ms-2 me-2">
                                            <img src="/zahidhr.jpg" width={30} height={30} className="rounded-circle" alt=""/>
                                        </div>
                                        <div className="">
                                            <div>Developed by Zahid HR</div> 
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-sm-4"> 
                            <Link to="#">
                                <div className="card mt-2 mb-3">
                                    <img src="/projects/21.jpg" className="portfolio-img" alt=""/>
                                    <div className="p-2">
                                        <h4 className="">Exam Software</h4>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <div className="ms-2 me-2">
                                            <img src="/zahidhr.jpg" width={30} height={30} className="rounded-circle" alt=""/>
                                        </div>
                                        <div className="">
                                            <div>Developed by Zahid HR</div> 
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-sm-4"> 
                            <Link to="#">
                                <div className="card mt-2 mb-3">
                                    <img src="/projects/22.jpg" className="portfolio-img" alt=""/>
                                    <div className="p-2">
                                        <h4 className="">POS Software</h4>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <div className="ms-2 me-2">
                                            <img src="/zahidhr.jpg" width={30} height={30} className="rounded-circle" alt=""/>
                                        </div>
                                        <div className="">
                                            <div>Developed by Zahid HR</div> 
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-sm-4"> 
                            <Link to="#">
                                <div className="card mt-2 mb-3">
                                    <img src="/projects/23.jpg" className="portfolio-img" alt=""/>
                                    <div className="p-2">
                                        <h4 className="">POS Software</h4>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <div className="ms-2 me-2">
                                            <img src="/zahidhr.jpg" width={30} height={30} className="rounded-circle" alt=""/>
                                        </div>
                                        <div className="">
                                            <div>Developed by Zahid HR</div> 
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-sm-4"> 
                            <Link to="#">
                                <div className="card mt-2 mb-3">
                                    <img src="/projects/24.jpg" className="portfolio-img" alt=""/>
                                    <div className="p-2">
                                        <h4 className="">Brick Field Software</h4>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <div className="ms-2 me-2">
                                            <img src="/zahidhr.jpg" width={30} height={30} className="rounded-circle" alt=""/>
                                        </div>
                                        <div className="">
                                            <div>Developed by Zahid HR</div> 
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-sm-4"> 
                            <Link to="#">
                                <div className="card mt-2 mb-3">
                                    <img src="/projects/25.jpg" className="portfolio-img" alt=""/>
                                    <div className="p-2">
                                        <h4 className="">Educational Management Software</h4>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <div className="ms-2 me-2">
                                            <img src="/zahidhr.jpg" width={30} height={30} className="rounded-circle" alt=""/>
                                        </div>
                                        <div className="">
                                            <div>Developed by Zahid HR</div> 
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-sm-4"> 
                            <Link to="#">
                                <div className="card mt-2 mb-3">
                                    <img src="/projects/26.jpg" className="portfolio-img" alt=""/>
                                    <div className="p-2">
                                        <h4 className="">Online Exam Software</h4>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <div className="ms-2 me-2">
                                            <img src="/zahidhr.jpg" width={30} height={30} className="rounded-circle" alt=""/>
                                        </div>
                                        <div className="">
                                            <div>Developed by Zahid HR</div> 
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className="col-sm-4"> 
                            <Link to="#">
                                <div className="card mt-2 mb-3">
                                    <img src="/projects/27.jpg" className="portfolio-img" alt=""/>
                                    <div className="p-2">
                                        <h4 className="">Company Website</h4>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <div className="ms-2 me-2">
                                            <img src="/zahidhr.jpg" width={30} height={30} className="rounded-circle" alt=""/>
                                        </div>
                                        <div className="">
                                            <div>Developed by Zahid HR</div> 
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                     
                     
                    </div>
                   
                </div>        
        </div>

    );
};

export default PortfolioDesign;