import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';

const Portfolios = () => {
    return (
        <div>
            <section>
                <div className="container p-3">
                    <h2 className="text-center mt-4 mb-3"><b>My Portfolio</b></h2>
                    <p className="text-center mx-auto percent-70">I am providing 100% efficient service online and is available to build a trusting working relationship with you. Please see my service portfolio.</p>
                
                    {/* <div className="mx-auto" style={{ width: '100%' }}>
                        <button type="button" className="btn btn-dark btn-border me-2 mb-2 button-fill" data-mdb-ripple-color="dark">Wordpress</button>
                        <button type="button" className="btn btn-primary btn-border me-2 mb-2 button-fill" data-mdb-ripple-color="dark">PHP Application</button>
                        <button type="button" className="btn btn-success btn-border me-2 mb-2 button-fill" data-mdb-ripple-color="dark">Laravel Application</button>
                        <button type="button" className="btn btn-danger btn-border me-2 mb-2 button-fill" data-mdb-ripple-color="dark">React+Node js Application</button>
                        <button type="button" className="btn btn-info btn-border me-2 mb-2 button-fill" data-mdb-ripple-color="dark">Next js Application</button>
                        <button type="button" className="btn btn-warning btn-border me-2 mb-2 button-fill" data-mdb-ripple-color="dark">Android and iOS </button>
                        <button type="button" className="btn btn-secondary btn-border me-2 mb-2 button-fill" data-mdb-ripple-color="dark">Desktop Application</button>
                    </div> */}

                    <div className="row">
                        <div className="col-sm-4"> 
                            <Link to="/service-view">
                                <div className="card mt-2 mb-3">
                                    <img src="/projects/01.jpg" className="portfolio-img" alt=""/>
                                  
                                    <div className="p-2">
                                        <h4 className="text-center">Exam Software</h4>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-sm-4"> 
                            <Link to="/service-view">
                                <div className="card mt-2 mb-3">
                                    <img src="/projects/02.jpg" className="portfolio-img" alt=""/>
                                    <div className="p-2">
                                        <h4 className="text-center">License Software</h4>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-sm-4"> 
                            <Link to="/service-view">
                                <div className="card mt-2 mb-3">
                                    <img src="/projects/03.jpg" className="portfolio-img" alt=""/>
                                    <div className="p-2">
                                        <h4 className="text-center">POS Software</h4>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-sm-4"> 
                            <Link to="/service-view">
                                <div className="card mt-2 mb-3">
                                    <img src="/projects/04.jpg" className="portfolio-img" alt=""/>
                                    <div className="p-2">
                                        <h4 className="text-center">Company Website</h4>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-sm-4"> 
                            <Link to="/service-view">
                                <div className="card mt-2 mb-3">
                                    <img src="/projects/17.jpg" className="portfolio-img" alt=""/>
                                    <div className="p-2">
                                        <h4 className="text-center">School Website</h4>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-sm-4"> 
                            <Link to="/service-view">
                                <div className="card mt-2 mb-3">
                                    <img src="/projects/06.jpg" className="portfolio-img" alt=""/>
                                    <div className="p-2">
                                        <h4 className="text-center">E-Commerce Website</h4>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-sm-4"> 
                            <Link to="/service-view">
                                <div className="card mt-2 mb-3">
                                    <img src="/projects/07.jpg" className="portfolio-img" alt=""/>
                                    <div className="p-2">
                                        <h4 className="text-center">Courier Service Website</h4>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-sm-4"> 
                            <Link to="/service-view">
                                <div className="card mt-2 mb-3">
                                    <img src="/projects/08.jpg" className="portfolio-img" alt=""/>
                                    <div className="p-2">
                                        <h4 className="text-center">Courier Service Website</h4>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-sm-4"> 
                            <Link to="/service-view">
                                <div className="card mt-2 mb-3">
                                    <img src="/projects/09.jpg" className="portfolio-img" alt=""/>
                                    <div className="p-2">
                                        <h4 className="text-center">E-Commerce Website</h4>
                                    </div>
                                </div>
                            </Link>
                        </div>
                       
                    </div>

                    <Link to="/portfolio-details">
                    <button className="mx-auto d-flex btn btn-sm btn-primary mt-3">View More <FontAwesomeIcon icon={faAngleDoubleRight} className="mt-1 ms-2"/></button>
                    </Link>
                    
                </div>
            </section>
        </div>
    );
};

export default Portfolios;