import React from 'react';
import { Link } from 'react-router-dom';
import TopNav from './TopNav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUserAlt } from '@fortawesome/free-solid-svg-icons';

const Header = () => {
    return (
        <div>
            
         <TopNav/>

            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container">
                    <Link className="navbar-brand me-2" to="/">
                        {/* <img src="/logo.png" style={{ width: '200px', height: '35px' }} alt=""/> */}
                        <b>CODE WITH ZAHID</b>
                    </Link>

                    <button
                        className="navbar-toggler"
                        type="button"
                        data-mdb-toggle="collapse"
                        data-mdb-target="#navbarButtonsExample"
                        aria-controls="navbarButtonsExample"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        >
                        <i className="fas fa-bars"></i>
                        <FontAwesomeIcon icon={faBars}/>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarButtonsExample">
                
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    
                    </ul>
                
                        <div className="d-flex align-items-center">
                            <ul className="navbar-nav mb-2 mb-lg-0">
                                <li className="nav-item custom-nav-style"> 
                                    <Link type="button" className="nav-link text-dark" to="/"><b>Home</b></Link>
                                </li>
                                <li className="nav-item custom-nav-style">
                                    <Link className="nav-link text-dark" to="/about"><b>About</b></Link>
                                </li>
                                <li className="nav-item custom-nav-style">
                                    <Link className="nav-link text-dark" to="/service"><b>Service+</b></Link>
                                </li>
                                <li className="nav-item custom-nav-style">
                                    <Link className="nav-link text-dark" to="/portfolio"><b>Portfolio</b></Link>
                                </li>
                                <li className="nav-item custom-nav-style">
                                    <Link className="nav-link text-dark" to="/team"><b>Team</b></Link>
                                </li>
{/*                                 
                                        <li className="nav-item custom-nav-style nav-hide">
                                            <Link className="nav-link text-dark" to="/login"><b>Login</b></Link>
                                        </li>
                                        <li className="nav-item custom-nav-style nav-hide">
                                            <Link className="nav-link text-dark" to="/reg"><b>Registration</b></Link>
                                        </li> */}
                              
                                <li className="nav-item custom-nav-style">
                                    <Link className="nav-link text-dark" to="/contact">
                                        <button type="button" className="btn btn-primary me-3">
                                            <b>Contact Us</b>
                                        </button>
                                    </Link>
                                </li>
                            </ul>
                         
                            
                        </div>

                    </div>

                </div>
            </nav>

        </div>
    );
};

export default Header;