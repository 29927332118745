import React from 'react';

const ServiceView = () => {
    return (
        <div>
            <div className='service-dark-bg'>
                <div className='container'><br/><br/>
                        <h2 className="text-center"><b>I am Moving Forward Successfully</b></h2>
                        <h2 className="text-center"><b>Due To Our Service</b></h2>
                        <p className="text-center mx-auto percent-70">We have a highly skilled team of experts who are professionals in web design and development. it is working 24/7 meaning at any time, all year round. Thus, we ensure the best of services wherever and whenever.</p>
                    
                    <div className=''>
                
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="card bg-remove-card bg-remove-layer-1 mb-3 text-light">
                                <div className="card-body text-center">
                                <h5 className="card-title text-center">Wordpress Development</h5>
                                    <img src="/wp.png" width={200} height={180} className="p-3" alt="Design"/>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-4">
                            <div className="card bg-remove-card bg-remove-layer-2 mb-3 mt-3 text-light">
                                <div className="card-body text-center">
                                <h5 className="card-title text-center">UI/UX Design</h5>
                                    <img src="/ui.png" width={200} height={180} className="p-3" alt="Design"/>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-4">
                            <div className="card bg-remove-card bg-remove-layer-3 mb-3 text-light">
                                <div className="card-body text-center">
                                <h5 className="card-title text-center">Custom Website Development</h5>
                                    <img src="/code.png" width={200} height={180} className="p-3" alt="Design"/>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-4">
                            <div className="card bg-remove-card bg-remove-layer-4 mb-3 text-light">
                                <div className="card-body text-center">
                                <h5 className="card-title text-center">Software Development</h5>
                                    <img src="/soft.png" width={200} height={180} className="p-3" alt="Design"/>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-4">
                            <div className="card bg-remove-card bg-remove-layer-5 mb-3 mt-3 text-light">
                                <div className="card-body text-center">
                                <h5 className="card-title text-center">Template Design</h5>
                                    <img src="/code.png" width={200} height={180} className="p-3" alt="Design"/>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-4">
                            <div className="card bg-remove-card bg-remove-layer-6 mb-3 text-light">
                                <div className="card-body text-center">
                                <h5 className="card-title text-center">Android/iOS Apps Development</h5>
                                    <img src="/code.png" width={200} height={180} className="p-3" alt="Design"/>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-4">
                            <div className="card bg-remove-card bg-remove-layer-7 mb-3 text-light">
                                <div className="card-body text-center">
                                <h5 className="card-title text-center">Desktop Apps Development</h5>
                                    <img src="/soft.png" width={200} height={180} className="mx-auto d-flex p-3" alt="Design"/>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-4">
                            <div className="card bg-remove-card bg-remove-layer-8 mb-3 mt-3 text-light">
                                <div className="card-body text-center">
                                <h5 className="card-title text-center">Website Development</h5>
                                    <img src="/code.png" width={200} height={180} className="p-3" alt="Design"/>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-4">
                            <div className="card bg-remove-card bg-remove-layer-9 mb-3 text-light">
                                <div className="card-body text-center">
                                <h5 className="card-title text-center">Search Engine Optimization-(SEO)</h5>
                                    <img src="/soft.png" width={200} height={180} className="p-3" alt="Design"/>
                                </div>
                            </div>
                        </div>

                        </div>
                    
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceView;