import React from 'react';
import { Link } from 'react-router-dom';
import ConvincedWork from '../components/ConvincedWork';
import Review from '../components/Review';
import TeamMember from '../components/TeamMember';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons';


const Team = () => {
    return (
        <div>
            <div className="bg-image">
                    <img
                        src="/service-bg.png"
                        className="img-fluid header-image"
                        alt="Sample"
                        width={1380}
                        height={480} 
                    />
                        <div className="mask" style={{ backgroundColor: 'rgba(0, 0, 200, 0.6)' }}>
                            <h1 className="text-center text-light p-4 mt-4">TEAM MEMBER</h1>
                                <p className="text-center text-light percent-70 d-flex mx-auto">
                                I am professional full stack web developer and always focuses on establishing long-term relationships with customers.
                            </p>
                            <div className="row">
                                <div className="col-sm-3"></div>
                                <div className="col-sm-6">
                                    <div className="mt-4 p-3 text-center">
                                            <Link to="/contact">
                                                <button type="button" className="btn btn-contact me-2">CONTACT</button>
                                            </Link>

                                            <a href="https://join.skype.com/invite/uaf7aAvVkbnx" target="_blank"><button type="button" className="btn btn-success skype-btm"><FontAwesomeIcon icon={faLocationArrow}/> Connect me on Skype</button></a>
                                    </div> 
                                </div>
                                <div className="col-sm-3"></div>
                            </div>
                    </div>
                </div>
            
                <TeamMember/>

                <ConvincedWork/>

                <Review/>
        </div>
    );
};

export default Team;