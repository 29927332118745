import React from 'react';

const WeAreProvide = () => {
    return (
        <div>
             <section>
                <div className="container-fluid mt-3 mb-3 p-3">
                    <h2 className="text-center"><b>I am providing</b></h2>
                    <p className="text-center mx-auto percent-70">
                    1. Wordpress development.
                    2. PHP, Laravel website and software development.
                    3. MERN full stack development.
                    4. Android/iOS apps development.
                    5. Desktop application development.
                       whenever you need them.That’s Affordable, High-Quality & Fast.</p>
                
                    <div className="container ">
                        <div className="row">
                            <div className="col-sm-4">
                                <div className="card service-card bg-light mb-3 text-dark">
                                    <div className="card-body text-center">
                                        <img src="/provide1.png" width={100} height={100} className="mx-auto d-flex p-3" alt="Design"/>
                                        <h5 className="card-title text-center"><b>Fast Delivery</b></h5>
                                        <p className="card-text text-center">Development time depends on the complexity of the product. However, we are capable of delivering within 15 days.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="card service-card bg-light mb-3 text-dark">
                                    <div className="card-body text-center">
                                        <img src="/provide2.png" width={100} height={100} className="mx-auto d-flex p-3" alt="Design"/>
                                        <h5 className="card-title text-center"><b>Fixed by Expert Developer</b></h5>
                                        <p className="card-text text-center">If any problems or errors are detected after develop your product, my expert developer will be fixed this bug quickly.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="card service-card bg-light mb-3 text-dark">
                                    <div className="card-body text-center">
                                        <img src="/provide2.png" width={100} height={100} className="mx-auto d-flex p-3" alt="Design"/>
                                        <h5 className="card-title text-center"><b>Mobile friendly design</b></h5>
                                        <p className="card-text text-center">We design products with different devices in mind. Such as mobile iOS web browser etc.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="card service-card bg-light mb-3 text-dark">
                                    <div className="card-body text-center">
                                        <img src="/provide4.png" width={100} height={100} className="mx-auto d-flex p-3" alt="Design"/>
                                        <h5 className="card-title text-center"><b>24/7 support</b></h5>
                                        <p className="card-text text-center">We are provide customer service strategy that involves providing support 24 hours a day, and 7 days a week</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="card service-card bg-light mb-3 text-dark">
                                    <div className="card-body text-center">
                                        <img src="/provide5.png" width={100} height={100} className="mx-auto d-flex p-3" alt="Design"/>
                                        <h5 className="card-title text-center"><b>Quick Turnaround</b></h5>
                                        <p className="card-text text-center">We provide quick turnaround services give you for your source code back in as little as four hours.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="card service-card bg-light mb-3 text-dark">
                                    <div className="card-body text-center">
                                        <img src="/provide6.png" width={100} height={100} className="mx-auto d-flex p-3" alt="Design"/>
                                        <h5 className="card-title text-center"><b>Dedicated Team</b></h5>
                                        <p className="card-text text-center">Once we know your needs for your project, we set up a dedicated team quickly to work on each of your projects.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default WeAreProvide;