import React from 'react';
import ContactSlider from '../components/ContactSlider';

const Contact = () => {
    return (
        <div>
            <ContactSlider/>
        </div>
    );
};

export default Contact;