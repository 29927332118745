import React from 'react';
import BgRemoveSlider from '../components/BgRemoveSlider';
import Contact from '../components/Contact';
import Counter from '../components/Counter';
import FAQ from '../components/FAQ';
import Review from '../components/Review';
import ServiceView from '../components/ServiceView';
import Team from '../components/Team';
import WeAreProvide from '../components/WeAreProvide';
import WhatWeDo2 from '../components/WhatWeDo2';

const SinglePortfolio = () => {
    return (
        <div>
            <BgRemoveSlider/>

            <WhatWeDo2/>

            <ServiceView/>

            <WeAreProvide/>

            <Counter/>

            <Team/>

            <FAQ/>

            <Review/>

            <Contact/>

        </div>
    );
};

export default SinglePortfolio;