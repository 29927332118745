import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const Team = () => {
    return (
        <div>
             <div>
                <div className='container p-3 mt-4'>
                    <div className='row'>
                        <div className='col-sm-6 mb-3'>
                            <Link to="/team">My Team Member</Link>
                            <h2><b>I Have Professionals in <br/> CODE WITH ZAHID.</b></h2>
                        </div>
                        <div className='col-sm-6'>
                            <p className='p-2'>We are the leading web development Team. We use different technology to build web application. Our technology is React JS, Node JS, Express JS, Mongo DB, PHP, Laravel, MySQL, React Native etc. </p>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-sm-4 mb-3'>
                            <div className="card" style={{ width: '18rem' }}>
                                <img src="/me.png" width={400} height={300} className="card-img-top" alt="Sunset Over the Sea"/>
                                <div className="card-body">
                                    <h5 className='text-center'><b>Zahid HR</b></h5>
                                    <p className='text-center text-primary'>Website Developer</p>
                                </div>
                            </div>
                        </div>

                        <div className='col-sm-4 mb-3'>
                            <div className="card" style={{ width: '18rem' }}>
                                <img src="/me.png" width={400} height={300} className="card-img-top" alt="Sunset Over the Sea"/>
                                <div className="card-body">
                                    <h5 className='text-center'><b>Zahid HR</b></h5>
                                    <p className='text-center text-primary'>Software Developer</p>
                                </div>
                            </div>
                        </div>

                        <div className='col-sm-4 mb-3'>
                            <div className="card" style={{ width: '18rem' }}>
                                <img src="/me.png" width={400} height={300} className="card-img-top" alt="Sunset Over the Sea"/>
                                <div className="card-body">
                                    <h5 className='text-center'><b>Zahid HR</b></h5>
                                    <p className='text-center text-primary'>Android/iOS Developer</p>
                                </div>
                            </div>
                        </div>
                     
                    </div>

                    <Link to="/team">
                        <button className="mx-auto d-flex btn btn-primary mt-3">See More <FontAwesomeIcon icon={faAngleDoubleRight} className="mt-1 ms-2"/></button>
                    </Link>

                </div>
            </div>
        </div>
    );
};

export default Team;