import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons';

const Slider = () => {
    return (
        <div>
             <section>
                <div className="container-fluid slider-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="mt-5">
                                    <h1 className="text-start text-light">I AM PROFESSIONAL</h1>
                                    <h1 className="text-start text-light">WEB AND SOFTWARE DEVELOPER</h1>
                                    <p className="text-light mt-4 mb-4">I am providing creative website and software development offering professional, affordable, and amazing development services.</p>
                                    
                                    <div className="mt-5">
                                            <Link to="/contact">
                                                <button type="button" className="btn btn-dark me-2" style={{ textTransform: 'none'}}>Hire Me</button>
                                            </Link>
                                            <a href="https://wa.me/+8801986686158"  target="_blank"><button type="button" className="btn btn-success skype-btm" style={{ textTransform: 'none'}}><FontAwesomeIcon icon={faLocationArrow}/> WhatsApp Me</button></a>
                                    </div>  
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="mt-4">
                                    <img src="/icon.png" className="p-2 slider-img"  alt="" /> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Slider;