import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const LogoDesignSlider = () => {
    return (
        <div>
            <section>
                <div className="container-fluid slider-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-6">

                                <div className="mt-5">
                                    <h1 className="text-start text-light">I AM PROFESSIONAL</h1>
                                    <h1 className="text-start text-light">WEB AND SOFTWARE DEVELOPER</h1>
                                    <p className="text-light mt-4 mb-4">I am providing of creative website and software development offering professional, affordable, and amazing development services.</p>
                                        <div className="mt-4 text-start">
                                            <Link to="/contact">
                                                <button type="button" className="btn btn-contact me-2">CONTACT</button>
                                            </Link>

                                            <a href="https://join.skype.com/invite/uaf7aAvVkbnx" target="_blank"><button type="button" className="btn btn-success skype-btm"><FontAwesomeIcon icon={faLocationArrow}/> Connect me on Skype</button></a>
                                        </div> 
                                 </div>
                            </div>

                        
                            <div className="col-sm-6">
                                <div className="mt-4">
                                    <img src="/logo-design.png" className="p-2 home-img" alt="" /> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default LogoDesignSlider;