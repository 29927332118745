import React from 'react';
import Contact from '../components/Contact';
import Counter from '../components/Counter';
import FAQ from '../components/FAQ';
import LogoDesignSlider from '../components/LogoDesignSlider';
import PortfolioDesign from '../components/PortfolioDesign';
import Progress from '../components/Progress';
import Review from '../components/Review';
import Team from '../components/Team';
import WhatWeDo2 from '../components/WhatWeDo2';

const ServiceDetails = () => {
    return (
        <div>

            <LogoDesignSlider/>

            <WhatWeDo2/>

            <Progress/>

            <PortfolioDesign/>

            <Counter/>

            <Team/>

            <FAQ/>

            <Review/>


            <Contact/>
        </div>
    );
};

export default ServiceDetails;