import React from 'react';
import { Link } from 'react-router-dom';
import Portfolios from '../components/Portfolios';
import Review from '../components/Review';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons';


const Portfolio = () => {
    return (
        <div>
             <div className='container-fluid portfolio-bg'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-6'>
                            <h1 className='text-start mt-5 p-3'><b>MY PROJECTS</b></h1>
                            <p className='text-start p-3'>A quick view of industry specific problems solved with development by the awesome team at code with zahid.</p>
                                    <div className="mt-4 p-3 text-start">
                                            <Link to="/contact">
                                                <button type="button" className="btn btn-contact me-2">CONTACT</button>
                                            </Link>

                                            <a href="https://join.skype.com/invite/uaf7aAvVkbnx" target="_blank"><button type="button" className="btn btn-success skype-btm"><FontAwesomeIcon icon={faLocationArrow}/> Connect me on Skype</button></a>
                                    </div> 
                        </div>
                        <div className='col-sm-6'></div>
                    </div>
                </div>
            </div>

            <Portfolios/>

            <Review/>

        </div>
    );
};

export default Portfolio;