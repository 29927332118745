import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const HowWeAre = () => {
    return (
        <div>
            <div className="container mt-5 mb-4">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="p-3">
                                <img src="/about-page.png" className='home-img' alt=""  />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="p-2">
                                <i className="fas fa-bullhorn mb-2" style={{ fontSize: '40px', transform: 'rotate(340deg)' }}></i>
                                <h2><b>who am I?</b></h2>
                                <p><b>I am professional freelancer for full stack development and ready to develop your perfect development.</b></p>
                                <p>I am providing of creative website and software development offering professional, affordable, and amazing development services.</p>
                                <div className='row'>
                                    <div className='col-sm-6'>
                                        <p><FontAwesomeIcon icon={faCheckCircle} className="text-primary"/> Professional freelance developer around the ready to create your Design.</p>
                                        <p><FontAwesomeIcon icon={faCheckCircle} className="text-primary"/> I am provide projects start to receive designs within a few hours.</p>
                                        <p><FontAwesomeIcon icon={faCheckCircle} className="text-primary"/> I am always here to help. Our representatives are available 24/7 to assist you with any issues.</p>
                                    </div>
                                    <div className='col-sm-6'>
                                        <p><FontAwesomeIcon icon={faCheckCircle} className="text-primary"/> My custom development starts at you want for one your affordable price.</p>
                                        <p><FontAwesomeIcon icon={faCheckCircle} className="text-primary"/> I provide quick turnaround for simple tasks, longer timelines for big projects.</p>
                                        <p><FontAwesomeIcon icon={faCheckCircle} className="text-primary"/> You can live chat with me to ask questions for your project.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
        </div>
    );
};

export default HowWeAre;