import { Route, Routes } from 'react-router-dom';
import './App.css';
import About from './pages/About';
import Contact from './pages/Contact';
import Dashboard from './pages/Dashboard';
import Error from './pages/Error';
import Forget from './pages/Forget';
import Home from './pages/Home';
import Login from './pages/Login';
import Portfolio from './pages/Portfolio';
import PortfolioDetails from './pages/PortfolioDetails';
import Reg from './pages/Reg';
import Service from './pages/Service';
import ServiceDetails from './pages/ServiceDetails';
import SinglePortfolio from './pages/SinglePortfolio';
import Team from './pages/Team';
import Footer from './Shared/Footer';
import Header from './Shared/Header';

function App() {
  return (
    <div className="App">
      
      <Header/>
      
      <Routes>
        <Route path='/' element={<Home/>}></Route>
        <Route path='/about' element={<About/>}></Route>
        <Route path='/service' element={<Service/>}></Route>
        <Route path='/service-details' element={<ServiceDetails/>}></Route>
        <Route path='/portfolio' element={<Portfolio/>}></Route>
        <Route path='/portfolio-details' element={<PortfolioDetails/>}></Route>
        <Route path='/service-view' element={<SinglePortfolio/>}></Route>
        <Route path='/team' element={<Team/>}></Route>
        <Route path='/contact' element={<Contact/>}></Route>
        <Route path='/login' element={<Login/>}></Route>
        <Route path='/reg' element={<Reg/>}></Route>
        <Route path='/forget' element={<Forget/>}></Route>
        <Route path='/dashboard' element={<Dashboard/>}></Route>
        <Route path='*' element={<Error/>}></Route>
      </Routes>

      <Footer/>

      

    </div>
  );
}

export default App;
